import React, { Component } from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Modal from "react-modal"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"

export const data = graphql`
    query($slug: String){
      allInstagramContent(limit: 36, sort: {fields: timestamp, order: DESC}) {
        nodes {
          id
          caption
          permalink
          media_type
          localImage {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 90) {
                aspectRatio
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      instagramContent(id: { eq: $slug }) {
        id
        localImage {
          childImageSharp {
            fluid(maxHeight: 1000, maxWidth: 1000, quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        permalink
        media_type
        caption
      }
    }
  `

export default class InspirationsPage extends Component {
  constructor(props) {
    super(props)

    let currentInspiration = undefined
    
    if (props.location.hash) {
      currentInspiration = props.data.allInstagramContent.nodes.find(el => {
        if (el.id === props.location.hash.slice(1)) {
          return el
        }
        return null
      }).node
    }
    if (props.pageContext.slug) {
      currentInspiration = props.data.instaNode
    }

    this.state = {
      modalIsOpen: false,
      currentInspiration: currentInspiration,
    }
    this.contentRef = React.createRef()
    this.closeRef = React.createRef()
  }
  componentDidMount() {
    if (
      !this.state.modalIsOpen &&
      (this.props.pageContext.slug || this.props.location.hash)
    ) {
      this.openModal(this.state.currentInspiration)
    }
  }

  openModal(inspiration, e) {
    this.setState({ modalIsOpen: true, currentInspiration: inspiration })
    if (e) {
      e.preventDefault()
      window.history.replaceState("", "", "/galeria/" + inspiration.id)
    }
  }

  toggleContent(e) {
    if (e.target.tagName === "rect") {
      return
    }
    let content = this.contentRef.current
    let close = this.closeRef.current
    content.classList.toggle("hidden")
    close.classList.toggle("hidden")
  }

  closeModal() {
    this.setState({ modalIsOpen: false, currentInspiration: undefined })
    if (this.props.location.pathName !== "/galeria") {
      window.history.replaceState("", "", "/galeria/")
    }
  }
  render() {
    const { data } = this.props
    return (
      <Layout className="subpage">
        <Modal
          closeTimeoutMS={200}
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal.bind(this)}
          contentLabel="Example Modal"
          className="Modal"
          overlayClassName="Overlay"
        >
          {this.state.currentInspiration && this.state.currentInspiration.localImage && (
            <>
              <a
                href="javascript:void(null)"
                className="boxclose"
                onClick={this.closeModal.bind(this)}
                ref={this.closeRef}
              ></a>
              <div
                onClick={this.toggleContent.bind(this)}
                className="image-wrapper"
              >
                <Img
                  fluid={
                    this.state.currentInspiration.localImage.childImageSharp
                      .fluid
                  }
                />
              </div>
              <div
                class="content"
                onClick={this.toggleContent.bind(this)}
                ref={this.contentRef}
              >
                {this.state.currentInspiration.caption && this.state.currentInspiration.caption.split('\n').map((item, key) => {
                    return <span key={key}>{item}<br/></span>
                })}
              </div>
            </>
          )}
        </Modal>
        <SEO
          title={
            this.state.currentInspiration && this.state.currentInspiration.localImage
              ? this.state.currentInspiration.title
              : "Galeria"
          }
          image={
            this.state.currentInspiration && this.state.currentInspiration.localImage
              ? this.state.currentInspiration.localImage.childImageSharp.fluid.src
              : undefined
          }
          description={
            this.state.currentInspiration && this.state.currentInspiration.localImage
              ? this.state.currentInspiration.description
              : "Dzielimy się inspiracjami! Zobacz z czego nasi architekci wnętrz czerpią inspirację."
          }
        />
        <Container fluid>
          <section>
            <h2 className="section-header">Galeria</h2>
            <Container fluid>
              <Row>
                <Col className="d-flex inspirations">
                  <p>
                    W naszym portfolio znajdują się projekty zarówno komercyjne jak i prywatne. 
                    Każdą realizacją zajmujemy się kompleksowo, zaczynając od projektu a kończąc na montażu. 
                    Nasze studio projektowe zajmuje się tworzeniem projektów koncepcyjnych, 
                    których finalną fazą są realistyczne wizualizacje, 
                    a nasz dział produkcyjny wciela te pomysły w życie.
                  </p>
                </Col>
              </Row>
            </Container>
            <Container fluid>
              <Row>
                <Col className="d-flex inspirations">
                  {data.allInstagramContent.nodes.map(
                    el =>
                      el.localImage && (
                        <div className="inspiration">
                          <Img fluid={el.localImage.childImageSharp.fluid} />
                          <div className="caption">
                            <span><a
                                href={"/galeria/" + el.id}
                                onClick={e => this.openModal(el, e)}
                              ><p>
                                {el.caption && el.caption.split('\n').map((item, key) => {
                                    if (key == 0) return <span>{item}<br/></span>
                                    return <>{item}<br/></>
                                })}
                                </p>
                              </a></span>
                          </div>
                          <a
                            href={"/galeria/" + el.id}
                            onClick={e => this.openModal(el, e)}
                            className="block-link"
                          ></a>
                        </div>
                      )
                  )}
                </Col>
              </Row>
            </Container>
          </section>
        </Container>
      </Layout>
    )
  }
}
